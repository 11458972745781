<template>
    <div class="container" :class='$mq'>
        <h1 :class='$mq'>Как привязать email Вконтакте</h1>
        <div class="nav" :class='$mq'>
            <div><a v-scroll-to="'#comp'">Я зашла с комьютера </a></div>
            <div><a v-scroll-to="'#phone'">Я сижу с телефона</a></div>
        </div>

        <div id="comp">
            <h2 :class='$mq'>С компьютера</h2>
            <p>1. Кликните на свою аватарку в правом верхнем углу и зайдите в пункт "Настройки"</p>
            <KImage class="image" :src="require('@/assets/membersh/error/comp2.png')"  alt="Vk1" width=""/>
            
            <p>2. В строке "Электронная почта" нажмите кнопку "Добавить"</p>
            <KImage class="image" :src="require('@/assets/membersh/error/comp3.png')"  alt="Vk2" width="100%"/>

            <p>3. Укажите адрес электронной почты и сохраните изменения (нужно ещё будет ввести пароль от ВК).</p>
            <KImage class="image" :src="require('@/assets/membersh/error/comp4.jpg')"  alt="Vk3" width="100%"/>

            <p>4. ВАЖНО! На новый e-mail придёт письмо, в нём будет ссылка, по которой нужно перейти, чтобы подтвердить действие.</p>

            <p>5. Попробуйте заново авторизоваться :)</p>
        </div>

        <div id="phone">
            <h2 :class='$mq'>С телефона</h2> 
            <p>1. В правом верхнем углу нажмите на иконку гамбургера</p>
            <KImage class="image" :src="require('@/assets/membersh/error/phone1.jpg')"  alt="Vk1" width="100%"/>
            
            <p>2. Внизу экрана выберите пункт "Настройки"</p>
            <KImage class="image" :src="require('@/assets/membersh/error/phone2.jpg')"  alt="Vk2" width="100%"/>
            
            <p>3. Нажмите "Управление аккаунтом VK Connect"</p>
            <KImage class="image" :src="require('@/assets/membersh/error/phone3.jpg')"  alt="Vk3" width="100%"/>
            
            <p>4. Выберите "Безопасность и вход"</p>
            <KImage class="image" :src="require('@/assets/membersh/error/phone4.jpg')"  alt="Vk4" width="100%"/>
            
            <p>5. В строке email добавьте свою почту</p>
            <KImage class="image" :src="require('@/assets/membersh/error/phone5.jpg')"  alt="Vk5" width="100%"/>

            <p>6. ВАЖНО! На новый e-mail придёт письмо, в нём будет ссылка по которой нужно перейти, чтобы подтвердить действие.</p>

            <p>7. Попробуйте заново авторизоваться :)</p>
        </div>
    </div>
</template>

<script>
    import KImage from "../Widgets/KImage";
    export default {
        components: {
            KImage
        }
    }
</script>

<style scoped>
    .container {
        max-width: 35em;
        font-size: 0.9em;
        line-height: 1.3em;
    }

    p {
        margin-bottom: 1em;
        margin-top: 2em;
    }

    h1 {
        font-size: 3em;
        line-height: 1em;
        margin-bottom: 0.5em;
        text-align: center;
        background-color: #e5e5e555;
        padding: 1em 2em;
    }
        h1.mobile {
            font-size: 2em;
            padding: 1em 0.5em;
        }

    h2 {
        font-size: 2em;
        font-family: 'Montserrat', sans-serif;
        line-height: 1em;
        margin-bottom: -0.5em;
        margin-top: 1em;
    }
        h2.mobile {
            font-size: 1.5em;
        }

    .image {
        display: flex;
        justify-content: center;
        margin: 1em 0;
        max-width: 100%;
    }
    
    a {
        color: #482431;
    }

    .nav {
        display: flex;
        justify-content: center;
        margin-top: -3em;
    }
        .nav.mobile {
            font-size: 0.8em;
            font-weight: 600;
            line-height: 1em;
            text-align: center;
            justify-content: space-between;
            margin: -3em auto 1em auto;
            padding: 0 1em;
        }

    .nav div {
        background-color: #ffb1a4;
        padding: 0.5em 1em;
        margin-right: 1em;
        transition: all 0.3s cubic-bezier(.54,0,.4,1.51);
    }
        .nav div:hover {
            background-color: #ffdad3;
        }

        .nav.mobile div {
            margin-right:0;
            width: 38%;
        }

</style>